.button{
    background-color: #F7878F;
    padding: 10px;
    margin: 2px;
    text-align: center;
}

.button a{
    margin: 10px;
    border: solid 2px #FFFFFF;
    border-radius: 10px;
    padding: 5px;
    background-color: #D7ADD9;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}